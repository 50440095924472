import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Email from '../components/email'

import './contact.scss'

function Contact() {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  return (
    <Layout>
      <SEO title="Contact" />
      <section className="contact-container">
        <div className="contact-header">
          <Email />
          <span style={{ marginLeft: '10px' }}>Email Us</span>
        </div>
        <div className="contact-body">
          <form
            className="contact-form"
            name="contact"
            method="post"
            action="/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <div hidden>
              <label>
                Don’t fill this out:{' '}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </div>
            <div className="contact-row">
              <label>
                Your name:
                <br />
                <input type="text" name="name" onChange={handleChange} />
              </label>
            </div>
            <div className="contact-row">
              <label>
                Your email address:
                <br />
                <input type="email" name="email" onChange={handleChange} />
              </label>
            </div>
            <div className="contact-row">
              <label>
                Message:
                <br />
                <textarea rows={10} name="message" onChange={handleChange} />
              </label>
            </div>
            <div className="contact-row">
              <button type="submit">Send</button>
            </div>
          </form>
        </div>
        <div className="contact-number">
          <label>
            Phone:
            <a href="tel:+1-801-472-6966">+1-801-472-6966</a>
          </label>
        </div>
        <div className="contact-address">
          Address:&nbsp;<address>931 N 60 E Orem, Utah 84057-3243</address>
        </div>
      </section>
    </Layout>
  )
}

export default Contact
